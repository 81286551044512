import * as React from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Main from '../styles/LandingPages'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import BottomPopup from '../components/BottomPopup'
import GirlOnPhone from '../images/footer-contact-bg.jpeg'

//
// markup

const StyledCenterDiv = styled.div`
    font-size: 1.5rem;
    margin: -170px auto 0;
    width: 1160px;
    h2 {
        font-size: 2rem;
        text-align: center;
    }
    ul {
        list-style: disc;
        padding: 30px;
    }
    .phones {
        font-size: 1.8rem;
        padding: 40px 0;
        h3 {
            font-size: 2rem;
        }
    }
    .womanOnPhoneWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
        }
    }

    @media (max-width: 667px) {
        margin: 0 auto;
        padding: 12px;
        width: 100%;
    }
`

const Quote = styled.div`
    background-color: #faab32;
    color: white;

    > div {
        margin: 0px auto;
        padding: 100px 0;
        width: 1160px;
    }
    h2 {
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
    }
    p {
        font-size: 1.5rem;
    }

    @media (max-width: 667px) {
        > div {
            padding: 12px;
            width: 100%;
        }
    }
`

const ContactPage = ({ location }) => {
    return (
        <Layout location={location}>
            <Main backgroundColor={'#ffffff'}>
                <title>Contact - Soumissions Avocat</title>
                <UpperForm homepage={true} />
                <StyledCenterDiv>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h2>
                                Merci pour l’intérêt que vous portez au service
                                de Soumissions Avocat.
                            </h2>
                            <p>
                                Notre équipe se fera un plaisir de vous répondre
                                le plus précisément possible et dans les plus
                                brefs délais. Vous pouvez nous appeler ou
                                remplir le formulaire sur cette page 24 heures
                                sur 24, 7 jours sur 7 !
                            </p>
                            <p>Désirez-vous nous contacter pour…</p>
                            <ul>
                                <li>obtenir réponse à vos questions</li>
                                <li>
                                    nous envoyer vos commentaires sur ce service
                                </li>
                            </ul>
                            <p>Vous êtes à la bonne place !</p>
                        </Grid>

                        <Grid
                            item
                            xs={4}
                            sm={4}
                            className="womanOnPhoneWrapper"
                        >
                            <img alt="Woman on phone." src={GirlOnPhone} />
                        </Grid>
                        <Grid item xs={8} sm={8} className="phones">
                            <h3>Vous pouvez nous appeler!</h3>
                            <p>1 (581) 702-8828</p>
                            <p>1 (514) 612-3612</p>
                        </Grid>
                    </Grid>
                </StyledCenterDiv>
                <Quote>
                    <div>
                        <h2>
                            <FormatQuoteIcon /> TÉMOIGNAGES DES UTILISATEURS
                        </h2>
                        <p>
                            Pour quelqu'un qui est nouveau dans une région et
                            qui ne sait où s'adresser, votre service a été le
                            bienvenu. Le service au téléphone a été super et une
                            prise de rendez-vous a été effectuée merci !
                        </p>
                        <small>- NICOLE BOLDUC</small>
                    </div>
                </Quote>
                <BottomForm />
            </Main>
            <BottomPopup />
        </Layout>
    )
}

export default ContactPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
